export function HelpAboutContent () {
  return (
    <div id="helpabout">
      <h3>About</h3>
      <ul>
        <li>I was inspired to create this game after playing the excellent <a href="https://london.metro-memory.com/">London Metro Memory</a> game. Major credit to <a href="https://twitter.com/_benjamintd?lang=en">@benjamintd</a>.</li>
        <li>I am aware that the URL is "findthetube" and the game is to find TfL stations. I bought the URL before I decided to include more than just the tube. I apologise to all the pedants out there, believe me it hurts me too.</li> 
        <li>If you want to donate towards the game, that is very kind! The map runs on <a href="https://leafletjs.com">Leaflet JS</a>, which was created by Volodymyr Agafonkin, a Ukrainian citizen. For that reason, if you can please donate to one of the charities and aid organisations at <a href="https://stand-with-ukraine.pp.ua/">Stand With Ukraine</a>.</li>
      </ul>
      <h3>Help</h3>
      <ul>
        <li>The goal of the game is to correctly identify all the Transport for London (TfL) stations by clicking the TfL roundel on the map.</li>
        <li>The game can be played two ways:</li>
            <ul>
              <li>1. Random mode: The game will display a station name at random.</li>
              <li>2. Challenge mode: You have to type each station name before clicking the relevant roundel.</li>
            </ul>
        <li>You can switch between the two game modes at any time.</li>
        <li>For a further challenge, you can turn off the lines on the map entirely. You will only see the TfL roundels.</li>
        <li>The lines are split into five networks: Tube, Overground, DLR, Elizabeth Line and London Trams.</li>
        <li>You can choose to display any combination of all five networks.</li>
        <li>Only the stations on networks you have selected will count towards your total score. In "random" mode only stations on lines selected will be prompted.</li>
        <li>If you are in Challenge ("type the station in") mode, you can use the following to speed things up:
          <ul>
            <li>The game doesn't care about capitalisation ("maida vale" works for "Maida Vale").</li>
            <li>The game doesn't care about spaces ("CanaryWharf" works for "Canary Wharf").</li>
            <li>The game doesn't care about full stops, hyphens, apostrophes or parentheses ("Kensington Olympia" works for "Kensington (Olympia)").</li>
            <li>The game treats "&" and "and" as the same thing ("Highbury & Islington" and "Highbury and Islington" are both fine).</li>
            <li>"Kings Cross" and "St. Pancras" are both acceptable for "Kings Cross St. Pancras".</li>
            <li>No-one has ever actually used the words "Cutty Sark for Martime Greenwich". "Cutty Sark" is fine.</li>
            <li>There are a few extra secret shortcuts to find too, if you really want to!</li>
          </ul>
        </li>
        <li>Some stations have multiple roundels (for example Canary Wharf). Clicking on any of the station roundels will be accepted as a correct guess.</li>
        <li>You can turn off display of found stations. This is very useful at the end of the game when the map gets very cluttered!</li>
      </ul>
      <h3>Attributions</h3>
      <ul>
        <li>Station data adapted from <a href="https://www.doogal.co.uk/london_stations">https://www.doogal.co.uk/london_stations</a> by Chris Bell</li>
        <li>Lines data adapted from data at <a href="https://github.com/oobrien/vis/tree/master/tubecreature">https://github.com/oobrien/vis/tree/master/tubecreature</a> by Oliver O'Brien</li>
        <li>TFL roundel from <a href="https://en.wikipedia.org/wiki/File:TfL_roundel_(no_text).svg">https://en.wikipedia.org/wiki/File:TfL_roundel_(no_text).svg</a> by Dream out loud</li>
        <li>Icons adapted from <a href="https://glyphs.fyi/">https://glyphs.fyi/</a> by Goran</li>
        <li>"Railway sans" font by <a href="https://github.com/davelab6/Railway-Sans">Greg Fleming</a></li>
      </ul>
      
    </div>
  )
}
