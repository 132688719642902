import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';

import {mangleStationName} from './Station.js'
import {updateLocalStorage} from './LocalStorage.js'
import {HelpAboutContent} from'./HelpAbout.js'

import {PlayMode} from './globals.js'

function SliderControl({ctrlId, ctrlName, checked, onChangeFn}) {
  const className = checked ? 'on' : 'off'
  return (
    <Form.Check inline label={ctrlName} type="switch" checked={checked} id={ctrlId + "-chk"} className={className} onChange={() => onChangeFn(!checked)}/>
  )
}

export function ConfigHelpAbout(props) {
  const [unlockStartNewGame, setUnlockStartNewGame] = useState(false);
  const [openControls, setOpenControls] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [prevIncludes, setPrevIncludes] = useState(props.includes);

  if (prevIncludes !== props.includes) {
    setPrevIncludes(props.includes)
    if (props.playMode === PlayMode.Random) {
      // The currently displayed random station name will not be visible if the line has been hidden
      // Rather than go thorugh the logic of working out if an update is actually needed, just generate a new station anyway.
      props.generateNewRandomStation()
    }
  }

  function onModeChange(e) {
    const newMode = (e.target.id === "typing-mode-radio") ? PlayMode.Typing : PlayMode.Random
    updateLocalStorage("settings", {"playMode": newMode})
    props.setPlayMode(newMode)

    if (newMode === PlayMode.Random) {
        props.generateNewRandomStation()
    } else {
        props.onStationGuessChange("")
        props.currentStationToFindRef.current = {"full": "", "mangled": ""}
    }
  }

  return (
    <>
      <div>
        <Button id="showHideControls" className="float-end" onClick={() => {setOpenControls(!openControls); setOpenHelp(false); }}>
          <img className="button-control" src="cog.svg" width="30px" alt="Show setup controls"/>
        </Button>
        <Button id="showHideHelp" className="float-end" onClick={() => {setOpenHelp(!openHelp); setOpenControls(false); }}>
          <img className="button-control" src="help.svg" width="30px" alt="Show help/about text"/>
        </Button>
      </div>
      <Collapse in={openControls}>
        <div id="controls" className="float-end">
          <h3>Setup</h3>
          <Form>
            <Form.Group>
              <Form.Label>Do you want see the lines on the map?</Form.Label>
              <SliderControl ctrlId={"show-lines"} ctrlName="" checked={props.showLines} onChangeFn={props.setShowLines}/>
              <Form.Label>Hide stations once they are found?</Form.Label>
              <SliderControl ctrlId={"hide-found"} ctrlName="" checked={props.hideFound} onChangeFn={props.setHideFound}/>
              </Form.Group>
          </Form>
          <Form>
            <Form.Group>
              <Form.Label>Which networks do you want in the game?</Form.Label>
              <SliderControl ctrlId={"inc-tube"} ctrlName={"Tube"} checked={props.includes["tube"]} onChangeFn={(e) => props.setIncludes({...props.includes, "tube": e})}/>
              <SliderControl ctrlId={"inc-dlr"} ctrlName={"DLR"} checked={props.includes["DLR"]} onChangeFn={(e) => props.setIncludes({...props.includes, "DLR": e})}/>
              <SliderControl ctrlId={"inc-overground"} ctrlName={"Overground"} checked={props.includes["overground"]} onChangeFn={(e) => props.setIncludes({...props.includes, "overground": e})}/>
              <SliderControl ctrlId={"inc-crossrail"} ctrlName={"Crossrail"} checked={props.includes["crossrail"]} onChangeFn={(e) => props.setIncludes({...props.includes, "crossrail": e})}/>
              <SliderControl ctrlId={"inc-tramlink"} ctrlName={"London Trams"} checked={props.includes["tramlink"]} onChangeFn={(e) => props.setIncludes({...props.includes, "tramlink": e})}/>
            </Form.Group>
          </Form>
          <Form>
            <Form.Group>
              <Form.Label>How do you want to pick stations to find?</Form.Label>
              <Form.Check
                type={"radio"}
                id={"typing-mode-radio"}
                label={`I want to type in each station name`}
                onChange={onModeChange}
                checked={props.playMode===PlayMode.Typing}
              />
              <Form.Check
                type={"radio"}
                id={"random-mode-radio"}
                label={`Give me station names randomly`}
                onChange={onModeChange}
                checked={props.playMode===PlayMode.Random}
              />
            </Form.Group>
          </Form>
          <Form>
            <Row>
              <Col xs="auto">
                <SliderControl ctrlId={"start-new-game-unlock"} ctrlName={"I want to start a new game"} checked={unlockStartNewGame} onChangeFn={setUnlockStartNewGame}/>
              </Col>
              <Col xs="auto">
                {unlockStartNewGame && 
                <input type="button" value="Start New Game" onClick={function () {
                  props.setPlayMode(PlayMode.Random)
                  setUnlockStartNewGame(false)
                  props.startNewGameFn()
                  }
                }/>}
              </Col> 
            </Row>
          </Form>
        </div>
      </Collapse>
      <Collapse in={openHelp}>
        <div id="helpabout" className="float-end">
          <HelpAboutContent {...props}/>
        </div>
      </Collapse>
    </>
  )
}

function StationNameInput(props) {
  if (props.playMode===PlayMode.Typing) {
    return (
      <Col xs={6}>
        <Form.Control type="text" ref={props.stationGuessInputRef} name="name-input" id="name-input" onInput={props.onInput}/>
      </Col>
    )  
  } else {
    return (null);
  }
}

function StationNameDisplay(props) {

  var toDisplay = props.currentStationToFindRef.current["full"]
  if (props.playMode===PlayMode.Random) {
    // Generate a new random station name if required
    if ((toDisplay === "") || (props.foundStations.includes(mangleStationName(toDisplay)))) {
      toDisplay = props.generateNewRandomStation()
    }
    return (<>
      <Col xs={4}>
        <div id="name-display">
          <span>{toDisplay}</span>
        </div>
      </Col>
      <Col xs={2}>
        <Button id="next-station-btn" onClick={props.onNewRandomStationClick}>Give Me Another!</Button>
      </Col>
    </>)
  } else {
    return (null);
  }
}

export function GameControls(props) {

  const [forceUpdateState, setForceUpdateState] = useState(0)

  function onNewTextEntry(event) {
    var entered_name = mangleStationName(event.target.value)

    // Apply special cases for Cutty Sark, Kings Cross, TCR
    if (entered_name === "cuttysark") { entered_name = "cuttysarkformaritimegreenwich" }
    if (entered_name === "kingscross") { entered_name = "kingscrossstpancras" }
    if (entered_name === "stpancras") { entered_name = "kingscrossstpancras" }
    if (entered_name === "kxsp") { entered_name = "kingscrossstpancras" }
    if (entered_name === "tcr") { entered_name = "tottenhamcourtroad" }
    if (entered_name === "highstreetken") { entered_name = "highstreetkensington" }
  
    props.onStationGuessChange(entered_name)
  }

  function onNewRandomStationClick(event) {
    props.generateNewRandomStation()
    setForceUpdateState(forceUpdateState+1)
  }

  if (props.gameComplete) {
    return <>
      <Col xs={3}>
        <div className={"game-complete"}><span><a href="https://tfl.gov.uk/corporate/careers/">Congratulations!</a></span></div>
      </Col>
      <Col xs={3}>
        <div className={"game-complete"}>
          <input type="button" value="Start New Game" onClick={function () {
              props.setPlayMode(PlayMode.Random)
              props.startNewGameFn()
            }}
          />
        </div>
      </Col>
    </>  
  } else {
    return (
      <>
        <Col xs={2}>
          <div className={"header-text"}><label  htmlFor={"name-input"}>The Next Stop Is</label></div>
        </Col>      
        <StationNameInput onInput={onNewTextEntry} {...props}/>
        <StationNameDisplay onInput={onNewTextEntry} onNewRandomStationClick={onNewRandomStationClick} {...props} />
      </>
    )
  }
}
