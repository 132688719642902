import { useState, useRef, useEffect } from 'react';

import { Logger } from 'react-logger-lib';

import ProgressBar from 'react-bootstrap/ProgressBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet'
import Control from 'react-leaflet-custom-control'

import {updateLocalStorage, loadLocalStorage} from './LocalStorage.js'

import {ConfigHelpAbout, GameControls} from './ControlsHelpAbout.js'

import {getStationDataAndComponents, mangleStationName} from './Station.js'
import {getLineComponents} from './Lines.js'

import {PlayMode, getRandomFromList} from './globals.js'

function LeafletMap({center, zoom, stations, lines, onZoomEnd, controls}) {

  function MapComponent() {
    useMapEvents({
      zoomend: onZoomEnd
    })
    return null
  }

  return (
      <MapContainer center={center} zoom={zoom} minZoom={10} maxZoom={16} scrollWheelZoom={true}>
        <MapComponent/>
        <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        subdomains='abcd'/>
        {lines}
        {stations}
        <Control>
          {controls}
        </Control>
      </MapContainer>
  )
}

function Statistics({numFoundStations, numUniqueStations}) {
  if (numUniqueStations > 0) {
    const percentFound = ((100 * numFoundStations) / numUniqueStations).toFixed(0)
    return (
      <Container>
        <Row>
          <Col xs={8} id="progress-bar-div">
            <ProgressBar className="position-relative">
              <div className="position-absolute d-flex justify-content-center w-100">{percentFound}%</div>
            <ProgressBar variant="score" now={percentFound} key={1} />
          </ProgressBar>
          </Col>
          <Col xs={4}id="statistics-div">
              <sup>{numFoundStations}</sup> &frasl; <sub>{numUniqueStations}</sub> found
          </Col>
        </Row>
      </Container>
    )
  }
}

export default function App() {

  const stationGuessInputRef = useRef(null)
  const currentStationToFindRef = useRef({"full":"","mangled":""});

  const [zoomLevelState, setZoomLevelState] = useState(11);

  const DEFAULT_INCLUDES = {"tube": true, "DLR": true, "overground": true, "crossrail": true, "tramlink": true}
  const [showLines, setShowLines] = useState(() => loadLocalStorage("settings", "showLines", true));
  const [hideFound, setHideFound] = useState(() => loadLocalStorage("settings", "hideFound", false));
  const [includes, setIncludes] = useState(() => loadLocalStorage("settings", "includes", DEFAULT_INCLUDES));
  const [foundStations, setFoundStations] = useState(() => loadLocalStorage("foundStations", "data", []));
  const [lastWrongStation, setLastWrongStation] = useState(["", false]);
  const [playMode, setPlayMode] = useState(() => loadLocalStorage("settings", "playMode", PlayMode.Random));

  function onZoomEnd(event) {
    setZoomLevelState(event.target.getZoom())
  }

  // Transform the station data JSON into Station components for display
  const stationData = getStationDataAndComponents(includes, foundStations, onStationRoundelClick, lastWrongStation, zoomLevelState, hideFound)

  function onStationGuessChange(newGuessFullName) {
    currentStationToFindRef.current = {"full": newGuessFullName, "mangled": mangleStationName(newGuessFullName)}
  }

  function onStationRoundelClick(mangledName) {

    var correctStation = false
    const mangledGuessName = currentStationToFindRef.current["mangled"]

    if (stationData.mangledStationNames.includes(mangledGuessName)) {
      if (!foundStations.includes(mangledName)) {
        if (mangledGuessName === mangledName) {
          Logger.of("App.onStationRoundelClick").info("Correct click on " + mangledName)
          setFoundStations([
            ...foundStations, mangledName
          ])
          correctStation = true
          currentStationToFindRef.current = {"full": "", "mangled": ""}
          if (stationGuessInputRef.current) {
            stationGuessInputRef.current.value = ""
            stationGuessInputRef.current.focus()
          }
        } else {
          Logger.of("App.onStationRoundelClick").info(mangledName + " is wrong guess for " + mangledGuessName)
        }
      } else {
        Logger.of("App.onStationRoundelClick").info(mangledName + " already found")
      }
    } else {
      Logger.of("App.onStationRoundelClick").info(mangledGuessName + " not in station list")
    }

    setLastWrongStation(correctStation ? ["", false] : [mangledName, true])
  }

  var lines = getLineComponents(showLines, includes)

  function startNewGame() {
    Logger.of("App").info("Starting new game")
    currentStationToFindRef.current = {"full": "", "mangled": ""}
    if (stationGuessInputRef.current) {
      stationGuessInputRef.current.value = ""
    }
    
    setShowLines(true)
    setHideFound(false)
    setIncludes(DEFAULT_INCLUDES)
    setFoundStations([])
    setLastWrongStation(["", false])
  }

  useEffect(() => updateLocalStorage("settings", {
    "showLines": showLines,
    "hideFound": hideFound,
    "includes": includes,
  }), [showLines, hideFound, includes]);

  useEffect(() => updateLocalStorage("foundStations", {"data": foundStations}), [foundStations]);

  function generateNewRandomStation() {
    const newRandomName = getRandomFromList(stationData.notFoundStationNames)
    Logger.of("Controls.GameControls.onRandomStationClick").info("Next random name = " + newRandomName)
    onStationGuessChange(newRandomName)
    return newRandomName
  }

  Logger.of("App").info("playMode=" + playMode, "tofind=" + currentStationToFindRef.current["mangled"])

  var configControls = <ConfigHelpAbout
    showLines={showLines} setShowLines={setShowLines}
    hideFound={hideFound} setHideFound={setHideFound}
    includes={includes} setIncludes={setIncludes}
    startNewGameFn={startNewGame} notFoundStationNames={stationData.notFoundStationNames}
    onStationGuessChange={onStationGuessChange} foundStations={foundStations}
    stationGuessInputRef={stationGuessInputRef}
    currentStationToFindRef={currentStationToFindRef}
    generateNewRandomStation={generateNewRandomStation}
    setPlayMode={setPlayMode} playMode={playMode}
  />

  const gameComplete = stationData.foundStationNames.length === stationData.uniqueStationNames.length;

  var gameControls = <GameControls notFoundStationNames={stationData.notFoundStationNames}
    onStationGuessChange={onStationGuessChange} foundStations={foundStations}
    stationGuessInputRef={stationGuessInputRef} currentStationToFindRef={currentStationToFindRef}
    playMode={playMode} generateNewRandomStation={generateNewRandomStation}
    gameComplete={gameComplete} setPlayMode={setPlayMode} startNewGameFn={startNewGame}
  /> 

  var statistics = <Statistics numFoundStations={stationData.foundStationNames.length} numUniqueStations={stationData.uniqueStationNames.length} />

  return <>
    <div id="container">
      <Container id="game-controls" fluid>
          <Row>
            {gameControls}
            <Col xs={4}>
              {statistics}
            </Col>
          </Row>
      </Container>
      <LeafletMap center={[51.508, -0.1262]} zoom={11} stations={stationData.stationComponents} lines={lines} onZoomEnd={onZoomEnd} controls={configControls}/>
    </div>
  </>
}
