import _ from 'lodash'
import { divIcon } from 'leaflet';
import { Marker } from 'react-leaflet'

import {TUBE_LINES} from './Lines.js'

import rawStationData from './stations.json';

export function getStationDataAndComponents(includes, foundStations, onStationRoundelClick, lastWrongStation, zoomLevelState, hideFound) {
    var stations = rawStationData["features"]
  // Only display stations that are on at least one displayed network
    var filteredStations = stations.filter(station =>
      (includes["tube"] && stationIsTube(station)) ||
      (includes["DLR"] && stationIsDLR(station)) ||
      (includes["overground"] && stationIsOverground(station)) ||
      (includes["crossrail"] && stationIsCrossrail(station)) ||
      (includes["tramlink"] && stationIsTramlink(station))
    )

    // Get a mapping of mangled station names -> actual station name and list of associated station data
    const uniqueStationsByMangledName = mapMangledNamesToStations(filteredStations)

    // From this, generate a list of unique station names for showing in the textbox in random play mode
    const mangledStationNames = Object.keys(uniqueStationsByMangledName)
    const uniqueStationNames = mangledStationNames.map(key => uniqueStationsByMangledName[key].dispName)
    addMangledNamesToStations(filteredStations)

    var [foundStationNames, notFoundStationNames]= _.partition(uniqueStationNames,
      unique_station => foundStations.includes(mangleStationName(unique_station))
    )

    var filteredStationsForDisplay = hideFound ? filteredStations.filter(station => !foundStations.includes(station.properties.mangledName)) : filteredStations;

    var stationComponents = filteredStationsForDisplay.map(function(station) {
      const stationIsFound = foundStations.includes(station.properties.mangledName);
      const onClick = stationIsFound ? null : onStationRoundelClick;

      return <Station station={station} showName={stationIsFound}
        onClick={onClick} key={station["properties"]["ATCOCode"]} size={zoomLevelState + 5}
        isWrong={lastWrongStation[1] && (lastWrongStation[0] === station.properties.mangledName)}
      />
    });
  
    return {
      mangledStationNames: mangledStationNames,
      uniqueStationNames: uniqueStationNames,
      stationComponents: stationComponents,
      foundStationNames: foundStationNames,
      notFoundStationNames: notFoundStationNames
    }
}

export function mapMangledNamesToStations(stations) {
  return stations.reduce(function (stationsDict, station) {
    const mangledName = mangleStationName(station.properties.name)
    if (mangledName in stationsDict) {
      stationsDict[mangledName].data.push(station)
    } else {
      stationsDict[mangledName] = {"dispName": station.properties.name, "data": [station]}
    }
    return stationsDict
  }, {});
}

export function addMangledNamesToStations(stations) {
  // Add mangled name to each station
  stations.forEach(station => station["properties"]["mangledName"] = mangleStationName(station["properties"]["name"]));
}

export function mangleStationName(name) {
    name = name || ""
    name = name.toLowerCase()
    name = name.replace(/\s/g, ""); // Remove whitespace
    name = name.replaceAll("&", "and"); // Replace ampersands with "and"
    name = name.replaceAll(".", ""); // Remove any punctuation
    name = name.replaceAll("'", "");
    name = name.replaceAll("(", "");
    name = name.replaceAll(")", "");
    name = name.replaceAll("-", "");
    return name
}

export function stationIsDLR(station) {
    return station.properties.lines.includes("DLR")
}

export function stationIsOverground(station) {
    return station.properties.lines.includes("London Overground")
}

export function stationIsCrossrail(station) {
    return station.properties.lines.includes("Elizabeth line")
}

export function stationIsTramlink(station) {
    return station.properties.lines.includes("Tramlink")
}

export function stationIsTube(station) {
    return TUBE_LINES.some(lineName => station.properties.lines.includes(lineName))
}

export function Station({station, onClick, showName, isWrong, size}) {
  const fullName = station["properties"]["name"]
  const mangledName = mangleStationName(fullName)

  var icon
  if (!isWrong) {
    icon = divIcon({
      className: "tfl-roundel" + (onClick ? "" : " no-cursor"),
      html: '<img class="tfl-roundel" width="' + size + '" src="tfl.svg"/><div>'+ (showName ? fullName : "" ) +'</div>'
    });
  } else {
    icon = divIcon({
      className: "incorrect-icon",
      html: '<img class="incorrect-icon" src="cross.svg"/>'
    });
  }

  var marker;
  if (onClick) {
    marker = <Marker position={station["geometry"]["coordinates"]} icon={icon} eventHandlers={{click: (e) => onClick(mangledName)}} key={station["properties"]["ATCOCode"]}/>
  } else {
    marker = <Marker position={station["geometry"]["coordinates"]} icon={icon} key={station["properties"]["ATCOCode"]}/>
  }

  return marker
}
