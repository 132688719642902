import { Logger } from 'react-logger-lib';

export function updateLocalStorage(storageKey, newState) {
  var savedState = JSON.parse(localStorage.getItem(storageKey))
  if (savedState) {
    for (const [key, value] of Object.entries(newState)) {
      savedState[key] = value
    }
    localStorage.setItem(storageKey, JSON.stringify(savedState))
  } else {
    localStorage.setItem(storageKey, JSON.stringify(newState))
  }
}

export function loadLocalStorage(storageKey, settingKey, defaultValue) {
  var savedState = JSON.parse(localStorage.getItem(storageKey))
  var data = defaultValue
  if (savedState) {
    data = (settingKey in savedState) ? savedState[settingKey] : defaultValue
  }

  Logger.of("loadLocalStorage").info("Loaded data " + data + " for key " + settingKey + " in storage key " + storageKey)

  return data
}