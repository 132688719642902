import { Polyline } from 'react-leaflet'
import { Logger } from 'react-logger-lib';

import rawLinesData from './lines.json';

export const TUBE_LINES = ['District','Piccadilly','Hammersmith & City','Metropolitan','Waterloo & City','Victoria','Central','Jubilee','Northern','Circle','Bakerloo'];

export function lineIsOverground(lineFeature) {
  const lineName = lineFeature.properties.line.name
  return ["Liberty", "Lioness", "Mildmay", "Suffragette", "Weaver", "Windrush"].some(name => lineName.includes(name))
}

export function lineIsDLR(lineFeature) {
    const lineName = lineFeature.properties.line.name
    return lineName.includes("DLR")
}

export function lineIsCrossrail(lineFeature) {
    const lineName = lineFeature.properties.line.name
    return lineName.includes("Elizabeth line")
}

export function lineIsTramlink(lineFeature) {
    const lineName = lineFeature.properties.line.name
    return lineName.includes("Tramlink")
}

export function lineIsTube(lineFeature) {
  const lineName = lineFeature.properties.line.name
  return TUBE_LINES.some(name => lineName.includes(name))
}

function getColor(name) {
  const colorMap = {
    'Elizabeth line': "rgb(119, 61, 189)",
    'District': "rgb(0, 121, 52)",
    'Piccadilly': "rgb(0, 15, 159)",
    'Hammersmith & City': "rgb(235, 155, 173)",
    'Metropolitan': "rgb(135, 15, 84)",
    'Waterloo & City': "rgb(107, 205, 178)",
    'DLR': "rgb(0, 175, 170)",
    'Tramlink': "rgb(118, 188, 33)",
    'Victoria': "rgb(0, 160, 223)",
    'Central': "rgb(225, 37, 27)",
    'Jubilee': "rgb(123, 134, 140)",
    'Northern': "rgb(0, 0, 0)",
    'Circle': "rgb(255, 205, 0)",
    'Bakerloo': "rgb(166, 90, 42)",
    "Liberty line": "rgb(103, 103, 103)",
    "Lioness line": "rgb(241, 180, 28)",
    "Mildmay line": "rgb(67, 126, 193)",
    "Suffragette line": "rgb(57, 185, 122)",
    "Weaver line": "rgb(151, 40, 97)",
    "Windrush line": "rgb(239, 77, 94)"
  }
  return colorMap[name]
}

export function getLineComponents(showLines, includes) {
  // The JSON lines data has one entry per set of TRACKS.
  // But LINES can shares tracks (e.g. the Bakerloo and the Watford DC overground)
  // So split up each entry on a per-line bases, duplicating the LineString data.
  // This makes it much easier to create a leaflet LineFeature per actual TfL line
  // and also filter them based on difficulty settings
  var linesData = rawLinesData["features"].flatMap(
    function (lineFeature) {
      return lineFeature.properties.lines.map(
        function (line) {
          let newLineFeature = JSON.parse(JSON.stringify(lineFeature));
          delete newLineFeature.properties.lines
          newLineFeature.properties.line = line
          return newLineFeature
        }
      );
    }
  );
  
  // Only show the lines that are selected
  if (showLines) {
    linesData = linesData.filter(function (lineFeature) {
      var include = false;
      include |= includes["tube"] && lineIsTube(lineFeature);
      include |= includes["DLR"] && lineIsDLR(lineFeature);
      include |= includes["overground"] && lineIsOverground(lineFeature);
      include |= includes["crossrail"] && lineIsCrossrail(lineFeature);
      include |= includes["tramlink"] && lineIsTramlink(lineFeature);
      return include;
    });
    linesData = linesData.map((lineData) => <Line lineFeature={lineData} key={lineData.properties.id + lineData.properties.line.name}/>)
  } else {
    linesData = []
  }
  return linesData
}

export function Line({lineFeature}) {

  // GeonJSON coordinates need swapping to make a Polyline
  const name = lineFeature.properties.line.name
  const coords = lineFeature.geometry.coordinates.map((c) => [c[1], c[0]])
  const lineColor = getColor(name)

  if (lineIsOverground(lineFeature))
  {
    Logger.of('App.Line.lineIsOverground').trace("Rendering overground line ID " + lineFeature.properties.id + ", " + name + ": " + lineColor)
    return (
      <>
        <Polyline className={"no-cursor"} key={lineFeature.properties.id + "_out"} color={lineColor} positions={coords} weight={4}/>
        <Polyline className={"no-cursor"} key={lineFeature.properties.id + "_in"} color={"rgb(255,255,255)"} positions={coords} weight={1}/>
      </>
    )
  } else {
    Logger.of('App.Line.lineIsOverground').trace("Rendering non-overground line ID " + lineFeature.properties.id + ", " + name + ": " + lineColor)
    return <Polyline className={"no-cursor"} key={lineFeature.properties.id + "_sng"} color={lineColor} positions={coords} weight={3}/>
  }
}

